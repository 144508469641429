import React from 'react';
import ServiceWidget from './ServiceWidget';

class ServiceLists extends React.Component {
  render () {
    return (
      <div className="service-widgets row mt-5">
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Milling
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Drilling
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Boring
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Honing
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Grinding
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Flange Facing
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Tapping and Reaming
          </ServiceWidget>
        </div>
        <div className="col-md-6 col-lg-4 mb-lg-3">
          <ServiceWidget icon="fa fa-circle ">
            Journal and Shaft Machining
          </ServiceWidget>
        </div>
      </div>
    );
  }
}

export default ServiceLists;
