import React from 'react';
import Layout from '../components/Layout';
import ServiceLists from '../components/ServiceLists';

const ServicesPage = () => {
  return (
    <Layout pageWrapper="page-contactus">
      <section className="breadcrumb">
        <div className="bg-image text-center">
          <h1>Services</h1>
        </div>
        <div className>
          <ul className="pager middle">
            <li>Home</li>
            <li><a href="javascript:void(0)">Services</a></li>
          </ul>
        </div>
      </section>
      <section className="main-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>Field Machining for All Industries</h3>
              <p>On Site Machine Company provides precision field machining services to a wide range of industries including Forging & Forming, Steel & Aluminum Production, Nuclear, Fossil, Hydro, Diesel and Wind Power Generation, Mining & Cement Mfg., Marine, Pulp & Paper Mfg., Petro-chemical, Infrastructure, General Mfg., Aerospace, and Scientific and Military customers. On Site Machine Company is ready to serve you.</p>
            </div>
          </div>

          <ServiceLists />

          <div className="row mt-5">
            <div className="col-md-12">
              <p><strong>Contact Onsite Machine Company today at 262.206.1655</strong> to experience onsite field machining like never before.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ServicesPage;
