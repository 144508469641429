import React from 'react';
import PropTypes from 'prop-types';

class ServiceWidget extends React.Component {
  render () {
    const { icon, children } = this.props;
    return (
      <div className="service-widget d-flex flex-row">
        <div className="d-flex flex-row align-items-center">
          <div className="mr-2">
            <i className={icon} />
          </div>
          <div>
            <h4>{children}</h4>
          </div>
        </div>
      </div>
    );
  }
}

ServiceWidget.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ServiceWidget;
